const DEV = {
  API_AUTHENTICATION: 'http://localhost:62074/authentication',
  API_SECURITY_ENDPOINT: 'http://localhost:59048/security',
  API_BIOMETRICS_ENDPOINT: 'http://localhost:52069/Biometrics/api/',
  IMAGE_MANAGER: 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa',
  API_LICENSE: 'https://qacorelicence.gse.com.co/license/api/'
}

const QA = {
  API_AUTHENTICATION:
    'https://jkcge88asd.execute-api.us-east-2.amazonaws.com/qa/authentication',
  API_SECURITY_ENDPOINT: 'https://qacoresecurity.gse.com.co/security',
  API_LICENSE: 'https://qacorelicence.gse.com.co/license/api/',
  API_BIOMETRICS_ENDPOINT: 'https://qabiometriaback.gse.com.co/Biometrics/api/'
}

const PRE = {
  API_AUTHENTICATION:
    'https://5sw5ah9606.execute-api.us-east-2.amazonaws.com/pre/authentication',
  API_SECURITY_ENDPOINT: 'https://precoresecurity.gse.com.co/security',
  API_LICENSE: 'https://precorelicence.gse.com.co/license/api/',
  API_BIOMETRICS_ENDPOINT: 'https://prebiometriaback.gse.com.co/Biometrics/api/'
}

const PRO = {
  API_AUTHENTICATION:
    'https://8gz2pd1dd2.execute-api.us-east-2.amazonaws.com/pro/authentication',
  API_SECURITY_ENDPOINT: 'https://coresecurity.gse.com.co/security',
  API_LICENSE: 'https://corelicence.gse.com.co/license/api/',
  API_BIOMETRICS_ENDPOINT: 'https://biometric.gse.com.co/Biometrics/api'
}

const environments = {
  DEV,
  QA,
  PRE,
  PRO
}
const env = process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE : 'PRO'

export const config = environments[env]
